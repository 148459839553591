<template>
    <div class="main-page" :dir="direction">
        <header>
            <h1>{{ $t(`message.pay_success`) }}</h1>

            <!-- 加载提示 -->
            <div v-if="isLoading" class="spinner-container">
                <div class="spinner"></div>
            </div>

            <!-- 错误提示和重试按钮 -->
            <div v-if="hasError">
                <p class="message">{{ msg }}</p>
                <p class="message">{{ $t(`message.load_failed`) }}</p>
                <button @click="fetchData">{{ $t(`message.load_retry`) }}</button>
            </div>

            <!-- 请求成功后展示的内容 -->
            <div v-if="!isLoading && !hasError">
                <p v-if="auto_charged" class="message" id="msgChanged">{{ $t(`message.vip_charged_success`) }}</p>
                <div v-if="!auto_charged" id="dataTable" class="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>{{ $t(`message.column_activation_code`) }}</th>
                                <th>{{ $t(`message.column_days`) }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(days, code) in jsonData" :key="code">
                                <td>{{ code }}</td>
                                <td>{{ days }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </header>
        <footer>
            <div class="footer-links">
                <a href="https://sites.google.com/view/amanlinkprivatepolicy"><u>Privacy Policy</u></a>
                <a href="https://sites.google.com/view/aman-link-user-agreement"><u>User Agreement</u></a>
                <a href="https://t.me/amanlinkvpn"><u>Telegram</u></a>
                <a href="https://www.instagram.com/amanlinkvpn/"><u>Instagram</u></a>
                <a href="mailto:norman@mfree.net"><u>Email</u></a>
            </div>
            <p>&copy; AmanLink. All rights reserved.</p>
        </footer>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    computed: {
        direction() {
            // List of RTL language codes
            const rtlLanguages = ['ar', 'he', 'fa', 'ur', 'az', 'ckb', 'ps', 'ug', 'dv'];
            // Check if the current locale is in the list of RTL languages
            return rtlLanguages.includes(this.$i18n.locale) ? 'rtl' : 'ltr';
        }
    },
    data() {
        return {
            isLoading: false,
            hasError: false,
            auto_charged: false,
            msg: "",
            jsonData: [],
        };
    },
    created() {
        console.log("created")
        this.operation_id = this.getQueryParam('m_operation_id');
        this.stripe_session_id = this.getQueryParam('stripe_session_id');
        this.webmoney_payment_no = this.getQueryParam('LMI_PAYMENT_NO');
    },
    mounted() {
        console.log("mounted")
        this.auto_charged = false
        this.fetchData();
    },
    methods: {
        getQueryParam(param) {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get(param);
        },
        async fetchData() {
            this.isLoading = true;
            this.hasError = false;
            try {
                const postData = JSON.stringify({ operationID: this.operation_id, stripe_session_id: this.stripe_session_id, webmoney_payment_no: this.webmoney_payment_no });
                var url = '/api/query_activation_code';
                // url = 'https://mfree.net' + url // for debug
                const response = await axios.post(url, postData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                console.log('Data:', response.data);
                if (response.data.success) {
                    this.hasError = false;
                    this.jsonData = response.data.data;
                    if ("all_used" in this.jsonData && this.jsonData["all_used"] === "1") {
                        this.auto_charged = true
                    } else {
                        this.auto_charged = false
                    }
                } else {
                    this.msg = response.data.message
                    // this.hideTable() // may be useless
                    this.hasError = true;
                }
            } catch (error) {
                this.hasError = true;
                console.error('Error fetching data:', error);
            } finally {
                this.isLoading = false;
            }
        },
    }
};
</script>

<style scoped>
table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
}

th {
    background-color: #f2f2f2;
}

.main-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    text-align: center;
    /* background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%); */
    color: black;
    font-family: Arial, sans-serif;
}

header {
    text-align: center;
    margin-top: 15%;
}

h1 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
}

p.message {
    font-size: 1.2em;
    color: #666;
}

.table-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

table {
    width: 80%;
    max-width: 600px;
    border-collapse: collapse;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

th,
td {
    border: 1px solid #ddd;
    padding: 12px 15px;
    text-align: left;
}

th {
    background-color: #f4f4f4;
    font-weight: bold;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

tr:hover {
    background-color: #f1f1f1;
}

.subtitle {
    font-size: 1.2rem;
    margin: 10px 0 20px;
}


.app-store-button {
    display: flex;
    gap: 20px;
    /* Adjust the gap size as needed */
}

.app-store-button img {
    padding: 0;
    margin: 0;
    display: block;
    width: 150px;
    height: auto;
}

footer {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
}

.footer-links {
    margin-bottom: 10px;
}

.footer-links a {
    color: black;
    text-decoration: none;
    margin: 0 10px;
}

.footer-links a:hover {
    text-decoration: underline;
}
/* 父容器的样式 */
.spinner-container {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center;     /* 垂直居中 */
  height: 30vh;           /* 设置高度为视口高度，确保垂直居中 */
}

/* 转圈效果的 CSS */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>